.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #75459B;
    border-color: #75459B;
}

.position-relative {
    padding-bottom: 10px;
}

.social-container {
    text-align: center;
}

.social-icons {
    list-style: none;
    margin-bottom: 0px;
    height: 50px;
}

.social-icons li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.2em;
}

.social-icons i {
    color: #fff;
    position: absolute;
    top: 17px;
    left: 17px;
    transition: all 265ms ease-out;
}

.social-icons a {
    display: inline-block;
}

.social-icons a:before {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    content: " ";
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: block;
    background: linear-gradient(45deg, #74429a, #c87fff);
    ;
    transition: all 265ms ease-out;
}

.social-icons a:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in;
}

.social-icons a:hover i {
    transform: scale(2.2);
    -ms-transform: scale(2.2);
    -webkit-transform: scale(2.2);
    color: #00B5F5;
    background: linear-gradient(45deg, #74429a, #c87fff);
    ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 265ms ease-in;
}

div.callout {
    background-color: #444;
    background-image: -moz-linear-gradient(top, #444, #444);
    position: relative;
    color: #ccc;
    padding: 10px;
    top: -25px;
    margin-left: 7px;
    border-radius: 3px;
    box-shadow: 0px 0px 20px #999;
    min-height: 50px;
    border: 1px solid #333;
    text-shadow: 0 0 1px #000;
}

.callout::before {
    content: "";
    width: 0px;
    height: 0px;
    border: 0.8em solid transparent;
    position: absolute;
}

.callout.top-right::before {
    right: 70px;
    bottom: 14px;
    transform: rotate(90deg);
    border-top: 10px solid #444;
}