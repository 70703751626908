.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #75459B;
  border-color: #75459B;
}

.position-relative {
  padding-bottom: 10px;
}

.progresses {
  display: flex;
  align-items: center;
}

.line-success {
  width: 50px;
  height: 6px;
  background: #63d19e;
}

.line {
  width: 50px;
  height: 6px;
  background: #ebebeb;
}

.steps {
  display: flex;
  background-color: #63d19e;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.rotate-link {
  display: inline-block;
  transition: transform 0.3s;
}

.rotate-link:active {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate-all {
  font-size: 20px;
  animation: rotate 1s linear infinite;
  color: green;
}

.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9,
.p10 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 10%, #fff 10%);
}

.p11,
.p12,
.p13,
.p14,
.p15,
.p16,
.p17,
.p18,
.p19,
.p20 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 20%, #fff 20%);
}

.p21,
.p22,
.p23,
.p24,
.p25,
.p26,
.p27,
.p28,
.p29,
.p30 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 30%, #fff 30%);
}

.p31,
.p32,
.p33,
.p34,
.p35,
.p36,
.p37,
.p38,
.p39,
.p40 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 40%, #fff 40%);
}

.p41,
.p42,
.p43,
.p44,
.p45,
.p46,
.p47,
.p48,
.p49,
.p50 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 50%, #fff 50%);
}

.p51,
.p52,
.p53,
.p54,
.p55,
.p56,
.p57,
.p58,
.p59,
.p60 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 60%, #fff 60%);
}

.p61,
.p62,
.p63,
.p64,
.p65,
.p66,
.p67,
.p68,
.p69,
.p70 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 70%, #fff 70%);
}

.p71,
.p72,
.p73,
.p74,
.p75,
.p76,
.p77,
.p78,
.p79,
.p80 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 80%, #fff 80%);
}

.p81,
.p82,
.p83,
.p84,
.p85,
.p86,
.p87,
.p88,
.p89,
.p90,
.p91,
.p92,
.p93,
.p94,
.p95,
.p96,
.p97,
.p98,
.p99 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84) 90%, #fff 90%);
}

.p100 {
  background: linear-gradient(to right, #63d19e 0%, rgb(84, 244, 84)100%, #fff 100%);
}

.progressBox {
  width: 100%;
}

.stripBar {
  background-color: #75459B !important;
  font-weight: bolder !important;
}

.skill {
  width: 25%;
  /* padding: 2px 5px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0.5px;
  font-size: 10px;
  margin: 2px;
  word-wrap: break-word;
  align-items: center;
  display: flex;
  color: #75459B;
  font-weight: bolder; */
}

.level {
  float: right;
  margin-left: 2px;
}