.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #75459B;
    border-color: #75459B;
}
.position-relative{
    padding-bottom: 10px;
}
.rdw-editor-main {
    height: 200px !important;
    overflow: auto;
    padding-left: 25px;
    padding-right: 10px;
    box-sizing: border-box;
}
.select-dropdown{
    border-color: #75459b;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
}