.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #75459B;
  border-color: #75459B;
}

.position-relative {
  padding-bottom: 10px;
}

/*  codyhouse.co */
.shift-divider {
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.shift-divider::before,
.shift-divider::after {
  content: '';
  height: 1px;
  background-color: #75459B;
  flex-grow: 1;
}

.shift-divider::before {
  margin-right: var(--text-divider-gap);
}

.shift-divider::after {
  margin-left: var(--text-divider-gap);
}

.profile-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  align-items: center;
  text-align: center;
  align-content: center;
}

.profile-item {
  display: flex;
  margin: 5px;
}