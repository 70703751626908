.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #75459B;
    border-color: #75459B;
}
.position-relative{
    padding-bottom: 10px;
}
.dataTables_filter{
    display: none;
}

.swal-button--cancel {
    background-color: red !important;
    color: white !important;
}