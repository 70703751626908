.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #75459B;
    border-color: #75459B;
}
.page-link{
    color: #000000;
}
.position-relative{
    padding-bottom: 10px;
}