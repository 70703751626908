@media screen and (min-width: 30em) {
    .grid_jobs {
        display: grid;
        grid-template-areas:
            "item  item"
            "desc   desc";
        grid-template-columns: 1fr  1fr;
    }

    .grid__description {
        grid-area: desc;
    }
}
.info-grid .jobname {
    grid-column: 2 / span 4 !important;
    grid-row: 1 / span 2;
    font-size: 1.2em;
    letter-spacing: .1rem;
    margin-top: 35px;
}
.info-grid .job {
    grid-column: 2 / span 4 !important;
    grid-row: 1 / span 2;

}
.title-text {
    margin-bottom: 0px;
    color: #263238;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 5px;
}
.view-more {
    grid-column: 3 / span 1 !important;
    grid-row: 4;
    text-align: center !important;
    font-size: 1.1em;
    cursor: pointer;
    width: auto !important;
}