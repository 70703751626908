@media (min-width: 768px) {
  .d-md-table-header-group {
    display: table-header-group !important;
  }
}

input[type="checkbox"].checkbox-custom {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2rem;
  height: 2rem;
  position: relative;
}

input[type="checkbox"].checkbox-custom::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: rgba(255, 255, 255, 0);
  background-color: white;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #75459b;
  font-size: 0rem;
  font-weight: bolder;
  /* line-height: 0.1rem; */
  text-align: center;
}

input[type="checkbox"].checkbox-custom:checked::after {
  content: "✔";
  color: white;
  background-color: #28A745;
  font-size: 1.9rem;
  text-align: center;
}

#dropdownBtnSort {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 5px;
  background-color: #302e2e;
  color: white;
  position: relative;
  --active-col: #302e2e;
}

#dropdownBtnSort i {
  float: right;
  transform: rotate(0deg);
}

#dropdownBtnSort::before {
  position: absolute;
  content: "";
  height: 2px;
  width: calc(100% - 20px);
  background-color: var(--active-sort-col);
  left: 10px;
  top: 2px;
}

#dropdownBtnFilter::before {
  position: absolute;
  content: "";
  height: 2px;
  width: calc(100% - 20px);
  background-color: var(--active-filter-col);
  left: 10px;
  top: 2px;
}

#dropdownSortOptions,
#dropdownFilterOptions {
  display: none;
  background-color: #302e2e;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  border: 2px solid white;
}

#dropdownFilterOptions {
  display: none;
  background-color: #302e2e;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  border: 2px solid white;
}

.sortOption,
.filterOption {
  padding: 10px;
  color: white;
  text-decoration: none;
}

.sortOption span,
.filterOption span {
  float: right;
}

.sortOption:hover,
.filterOption:hover {
  background-color: #6e6e6e;
}

.selected {
  position: relative;
  color: #f5b32f;
}

.selected::before {
  position: absolute;
  content: "";
  height: 2px;
  width: calc(100% - 20px);
  /* background-color: #F5B32F; */
  left: 10px;
  bottom: 7px;
}

#clearBtn {
  height: 38.5px;
  padding: 0 10px;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 5px;
  background-color: #302e2e;
  color: white;
  margin-left: 20px;
}

.react-datetimerange-picker {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #75459b;
  border-radius: 20px;
  box-sizing: border-box;
}
.react-datetimerange-picker__wrapper {
  border: 0px !important;
}