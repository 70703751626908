.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #75459B;
    border-color: #75459B;
}

.position-relative {
    padding-bottom: 10px;
}

#small {
    width: auto !important;
}

.partial-timer {
    border: 2px solid #f0ad4e;
    border-radius: 0px 17px 17px 0px;
    padding: 1px
}

.timer-admin {
    border: 1px solid #000;
    border-radius: 0px 50px 50px 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.time-range-container {
    position: relative;
    background-color: #fff;
    border: 2px solid #000;
    /* Add a border */
    border-radius: 25px 25px 25px 25px;
    padding: 10px;
    /* Add some padding for better spacing */
    display: inline-block;
    /* Keep everything on the same line */
}

.time-range-container input[type="time"] {
    border: none;
    /* Remove input border */
    outline: none;
    /* Remove input outline */
}

.time-range-container input[type="text"] {
    border: none;
    /* Remove input border */
    outline: none;
    /* Remove input outline */
    max-width: 100px;
}

.time-range-container span {
    margin: 0 5px;
    /* Add spacing between elements */
}

.suffix-container-list-update {
    background-color: #75459B;
    height: 15px;
    width: auto;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    line-height: 15.5px;
    right: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
}


.suffix-container-list-update-left {
    background-color: #75459B;
    height: 15px;
    width: auto;
    border-radius: 5px;
    position: absolute;
    top: -8px;
    line-height: 15.5px;
    left: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    z-index: 1;
}
.suffix-container-list-status {
    height: 15px;
    width: auto;
    border-radius: 5px;
    position: absolute;
    top: -27px;
    line-height: 15.5px;
    left: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    z-index: 1;
}
.suffix-container-list-exported {
    height: 15px;
    width: auto;
    border-radius: 5px;
    position: absolute;
    top: -27px;
    line-height: 15.5px;
    left: 120px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    z-index: 1;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover{
    color:rgb(255, 255, 255) !important;
    background-color: #0074d9 !important;
}