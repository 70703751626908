a {
  text-decoration: none;
}

.user-card-full {
  user-select: text;
}

.success-tag {
  background-color: #388e3c;
}

.carousel-radius {
  border-radius: 0 0 65px 0px;
}

.countdown-text {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  justify-content: flex-end;
}

.countdown-text>p {
  font-size: 0.8rem;
  font-weight: 500;
  color: #637381;
}

.countdown-text>button {
  background-color: #fff;
  border: none;
  color: #ff5630;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.label {
  /* font-family: "Roboto"; */
  font-weight: 400;
  font-size: 14px;
}

/* table#table1 {
    width: auto !important;
} */

.btn-custom-light {
  /* color: #fff;
    background-color: #adadea;
    border-color: #8282C8;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center; */
  color: #fff;
  width: 150px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.linear {
  border-radius: 45px 45px 0 0;
  background: linear-gradient(180deg,
      rgba(117, 69, 155, 1) 0%,
      rgba(178, 107, 234, 1) 100%) !important;
}

.internship {
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #75449b;
  background: #f2d2ff;
}

.internship:hover {
  box-shadow: 0 3px 9px rgba(96, 94, 94, 0.17);
}

.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.custom-container {
  width: 90%;
}

.links {
  /* text-decoration: none; */
  font-size: 1.125rem;
  font-weight: 600;
  color: #034a59;
  cursor: pointer;
  padding: 0 17px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  line-height: 37px;
  margin-top: 10px;
  margin-bottom: 12px;
  background: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-search-btn {
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: white;
  color: rgb(0, 0, 0);
  /* border: none; */
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-width: 0.5px;
  border-color: #a373c7;
}

.custom-search-btn:hover {
  background-color: #a373c7;
  text-decoration: none;
  color: #fff;
  border-color: #a373c7;
}

.custom-btn-header {
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: white;
  color: rgb(0, 0, 0);
  border: solid;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-width: 0.5px;
  border-color: #a373c7;
}

.custom-btn-header:hover {
  background-color: #a373c7;
  text-decoration: none;
  color: #fff !important;
  border-color: #a373c7;
}

.theme-header {
  width: 100%;
  display: block !important;
}

.navbar {
  background-color: white;
  top: 0px !important;
}

.search-section {
  display: grid;
  grid-template-columns: 35% 35%;
  justify-content: center;
}

.navbar-brand {
  padding: 5px !important;
}

.viewMore {
  text-align: center;
  margin-top: 50px;
}

.groupHeading {
  color: #75459b !important;
  font-weight: bolder !important;
  padding: 5px 15px !important;
  pointer-events: none !important;
  border-top: 2px solid lightgray;
}

.custom-content {
  padding-bottom: 10%;
  padding-top: 6%;
}

.sticky-div {
  position: absolute;
  top: 155px;
  left: 20px;
  right: 20px;
}

.fix-sticky-div .sticky-div {
  position: fixed;
  top: 10px;
}

.is-sticky {
  position: fixed;
  left: 0px;
  padding: 33px;
  width: 100%;
  z-index: 1100;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: white !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 25px;
  padding-bottom: 25px;
}

.searchWrapper {
  border: 0 !important;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
  position: relative;
}

.float-search {
  top: 85%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.float-search-staffs {
  top: 90%;
  width: 50%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.home-float-search-staffs {
  top: 80%;
  width: 100%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.login-head {
  display: flex;
  justify-content: center;
  color: #fff;
}

.img-res {
  max-width: 75%;
}

.top-padding {
  top: 160px;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin-left: 15px;
}

.did-floating-label-content {
  position: relative;
}

.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 10px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-labe-astrisk::after {
  content: "*";
  color: red;
}

.did-floating-label-custom {
  /* font-size: 13px; */
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #faf1ff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

::-webkit-file-upload-button {
  display: none;
}

.file-placeholder::before {
  content: "Click Here To Upload";
}

.did-floating-input {
  font-size: 12px;
  /* display: block; */
  width: 100%;
  height: 40px;
  padding: 0 20px;
  line-height: 35px;
  background: #fff;
  color: #323840;
  border: 1px solid #75459b;
  border-radius: 20px;
  box-sizing: border-box;
}


.did-floating-input:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
  /* opacity: 0.5;
    filter: opacity(0.5); */
  border-color: gray;
}

.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #faf1ff;
  color: #323840;
  border: 1px solid #75459b;
  border-radius: 5px;
  box-sizing: border-box;
}

.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  border: 1px solid blue;
}

.did-floating-input:focus~.did-floating-label,
.did-floating-select:focus~.did-floating-label-custom {
  top: -8px;
  font-size: 13px;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -8px;
  font-size: 14px;
  font-weight: 500;
}

/* Float Dropdown */
.did-floating-dropdown {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #75459b;
  border-radius: 20px;
  box-sizing: border-box;
}

.border-color-common {
  border: 1px solid #75459b;
}

/* .did-floating-dropdown:disabled{
    background: rgb(232, 231, 231);
}
 */

.did-floating-dropdown:focus {
  outline: none;
}

select.did-floating-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-dropdown::-ms-expand {
  display: none;
}

.did-floating-label-top {
  top: -8px !important;
  font-size: 13px;
}

.did-floating-label-down {
  top: 11px !important;
  font-size: 13px;
}

/* Select Box */

.did-floating-select:not([value=""]):valid~.did-floating-label-custom {
  top: -8px;
  font-size: 13px;
}

.social-button {
  background-position: 25px 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  border-radius: 3px;
  margin: 10px 7px auto;
  outline: rgb(255, 255, 255) none 0px;
  transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#facebook-connect {
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(60, 90, 154);
  border-radius: 50px;
  align-items: center;
}

#facebook-connect:hover {
  border-color: rgb(60, 90, 154);
  background: rgb(60, 90, 154);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#facebook-connect span {
  box-sizing: border-box;
  color: rgb(60, 90, 154);
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgb(255, 255, 255);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#facebook-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#google-connect {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 74, 61);
  align-items: center;
}

#google-connect:hover {
  border-color: rgb(220, 74, 61);
  background: rgb(220, 74, 61);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#google-connect span {
  box-sizing: border-box;
  color: rgb(220, 74, 61);
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border: 0px none rgb(220, 74, 61);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#google-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#linkedin-connect {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 119, 181);
  align-items: center;
}

#linkedin-connect:hover {
  border-color: rgb(0, 119, 181);
  background: rgb(0, 119, 181);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#linkedin-connect span {
  box-sizing: border-box;
  color: rgb(0, 119, 181);
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border: 0px none rgb(0, 119, 181);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#linkedin-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.grid__item {
  align-items: center;
  min-height: 300px;
  font-size: 5em;
  color: inherit;
  text-decoration: none;
  background: #fff;
  margin: 10px;
}

.grid__item:hover {
  color: #782db6;
}

.grid__item:focus+.grid__description {
  max-height: 200px;
  transition: max-height 0.4s ease-in;
}

.grid__description {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: max-height 0.15s ease-in;
  display: inline-block;
  position: relative;
}

.box {
  width: 100%;
  margin: 15px auto;
  background-color: #8282c8;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  position: relative;
  border-radius: 10px;
}

.sb1:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #00bfb600;
  border-right: 10px solid transparent;
  border-top: 10px solid #00bfb600;
  border-bottom: 10px solid #8282c8;
  right: 0;
  left: 13px;
  top: -19px;
}

.grid__description div {
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media screen and (min-width: 30em) {
  .grid {
    display: grid;
    grid-template-areas:
      "item item item"
      "desc desc  desc";
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid__description {
    grid-area: desc;
  }
}

@media screen and (min-width: 30em) {
  .grid-job {
    display: grid;
    grid-template-areas:
      "item  item"
      "desc   desc";
    grid-template-columns: 1fr 1fr;
  }

  .grid__description {
    grid-area: desc;
  }
}

.svg-background {
  width: 90%;
  height: 100%;
  border-radius: 13px 0 0 12px;
  top: 0;
  left: 0;
  background-color: #8282c8;
  -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  clip-path: polygon(0 0, 14% 0, 86% 100%, 0% 100%);
}

.svg-background2 {
  width: 100%;
  height: 100%;
  border-radius: 13px 0 0 12px;
  top: 0;
  left: 0;
  background-color: red;
  -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  z-index: -9;
}

.profile-img {
  position: absolute;
  width: 100px;
  /* height: 100px; */
  border-radius: 50%;
  margin-left: 50px;
}

.circle {
  position: absolute;
  width: 162px;
  height: 161px;
  left: 0;
  top: 0;
  background-color: #eceff1;
  border-radius: 50%;
  margin-top: 50.5px;
  margin-left: 35px;
}

.text-container {
  text-align: start;
  left: 0;
  width: 100%;
}

.desc-text {
  font-size: 10px;
  margin-top: 14px;
}

.container-profile {
  position: relative;
  background-color: #eceff1;
  height: 150px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px -10px rgb(226 226 226 / 75%);
}

.tag {
  top: 14px;
  display: block;
  width: 100px;
  height: 25px;
  color: rgb(226, 88, 34);
  transform: rotate(45deg);
  position: absolute;
  text-align: center;
  line-height: 24px;
  right: -24px;
  font-family: arial;
  font-size: 15px;
  background: linear-gradient(254deg,
      rgba(130, 130, 200, 1) 0%,
      rgba(187, 189, 244, 1) 100%);
}

/* CARD DESIGN STAFF SEARCH START*/
.front-side {
  height: 300px;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 4% 92% 4%;
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  background-color: #ffffff;
}

.front-side-sekelton {
  margin: 5px;
  overflow: hidden;
  background-color: #ffffff;
}

.title-text {
  margin-bottom: 0px;
  color: #263238;
  font-size: 25px;
  font-weight: 600;
  margin-top: 5px;
}

.info-text {
  margin-top: 10px;
  /* font-size: 15px; */
  font: message-box;
}

.info-address {
  margin-top: 10px;
  font-size: 12px;
}

.web {
  font-size: 15px;
  font-weight: bold;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: repeat(8, 50px);
  grid-gap: 8px;
  width: 150px;
  height: 400px;
  transform: rotate(-45deg);
}

.black {
  background-color: #ffffff;
  grid-column: 2 / span 2;
  grid-row: 1 / span 3;
}

.red1 {
  background-color: #ffffff;
  grid-row: 2 / span 5;
}

.red2 {
  background-color: #ffffff;
  grid-column: 2 / span 2;
  grid-row: 4/7;
}

.green {
  background-color: #ffffff;
  grid-column: 2/4;
  grid-row: 7/9;
}

.info-grid {
  grid-column: 2/3;
  grid-row: 2/3;
  z-index: 2;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.363); */
  background: linear-gradient(187deg,
      rgba(251, 251, 251, 1) 0%,
      rgba(176, 176, 245, 1) 100%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.432);
  border-radius: 6px;
  font-size: 0.7rem;
  /*12px;*/
  display: grid;
  grid-template-columns: 15px repeat(3, 1fr) 15px;
  grid-template-rows: repeat(3, 1fr);
  line-height: 1px;
}

.info-grid .name {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  font-size: 1.2em;
  letter-spacing: 0.1rem;
  margin-top: 8px;
}

.info-grid .signup {
  grid-column: 2 / span 2;
  display: flex;
  grid-row: 2 / span 1;
  font-size: 1.2em;
  letter-spacing: 0.1rem;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.info-grid .name h5 {
  font-weight: unset;
}

.info-grid .addr {
  grid-column: 2 / span 2;
  grid-row: 3;
}

.info-grid .vaccinated {
  grid-column: 4 / span 1;
  grid-row: 1;
  text-align: end;
  margin: 0px;
  display: flex;
  right: 23px;
  position: absolute;
}

.info-grid .emailId {
  grid-column: 3 / span 2;
  grid-row: 3;
  text-align: end;
  font-size: 1.1em;
  margin-top: 50px;
}

.view-more {
  grid-column: 2 / span 2;
  grid-row: 4;
  text-align: end;
  width: 100px;
  font-size: 1.3em;
  cursor: pointer;
}

.vaccinated-img {
  height: 60px;
  width: 60px;
}

/* CARD DESIGN STAFF SEARCH END*/

.list {
  color: #263238;
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.list:hover {
  color: #782db6;
}

/* FLIP CARD */
.flip-card-inner {
  cursor: pointer;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.list:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

/* END */

.signup-link {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.admin-link:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
  /* text-decoration-color: red !important; */
}

.pointer {
  cursor: pointer !important;
}
.pointer-link {
  cursor: pointer !important;
}
.pointer-link:hover {
  cursor: pointer !important;
  text-decoration: underline;
}

.otpContainer {
  display: flex;
  justify-content: center;
  margin: 5% auto;
}

.otpInput {
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
}

#watermark {
  position: absolute;
  right: 0;
  left: 0;
  opacity: 1;
  z-index: 99;
  color: rgb(255, 0, 0);
  font-size: 20px;
  text-align: center;
}

.social-btn:hover {
  color: #9b5dcc;
  background-color: #ffffff;
  border-color: #ffffff;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 999 !important;
}

.swal-button--confirmChange {
  background-color: #f44336 !important;
}

.swal-button--anotherButton {
  background-color: #4caf50 !important;
  /* Blue color for the "ANOTHER BUTTON" button */
}

.swal-button {
  padding: 7px 19px;
  border-radius: 8px;
  color: #fff;
  background-color: #75449b;
  font-size: 12px;
  border: 1px solid #75449b;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #8282c8;
}

.swal-button:not([disabled]):hover {
  background-color: #8282c8;
}

.swal-title {
  font-size: 20px;
}

.rate {
  float: left;
  height: 10px;
  /* padding: 0 10px; */
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked)>label:before {
  content: "★ ";
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

.rate-view {
  float: left;
  height: 10px;
  /* padding: 0 10px; */
}

.rate-view:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate-view:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 25px;
  color: #ccc;
}

.rate-view:not(:checked)>label:before {
  content: "★ ";
}

.rate-view>input:checked~label {
  color: #ffc700;
}

.badge-tag {
  position: absolute;
  right: -4px;
  top: 3px;
  border: solid 2px;
  border-radius: 50%;
  padding: 0.35em 0.65em;
  font-size: 0.45em;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-tag-extention {
  border: solid 2px;
  border-radius: 100%;
  position: absolute;
  top: -2px;
  padding: 0.3em 0.60em;
  font-size: 15px;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
}

.badge-tag-admin {
  position: absolute;
  right: 9px;
  top: 19px;
  border: solid 2px;
  border-radius: 92%;
  padding: 0px 5px 0px 5px;
  font-size: 0.56em;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

select option:hover {
  box-shadow: 0 0 10px 100px green inset;
  color: white;
}

.indexedStep {
  color: white;
  width: 100px;
  height: 50px;
  /* font-size: 20px; */
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.indexedStep.accomplished {
  background-color: #75449b;
}

.indexedStepOnboarding {
  color: white;
  width: 90px;
  height: 50px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  background-color: rgb(211 211 211);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px
}

.indexedStepOnboarding.accomplishedOnboarding {
  background-color: #75449b;
}


.tab-title {
  font-size: 10px;
}

.bookmark-filled {
  z-index: 9999;
  position: relative;
  height: 30px;
  width: 19px;
  padding: 0px;
  transform: rotate(0deg) skew(0deg);
  border-left: 12px solid #75449b;
  border-right: 12px solid #75449b;
  border-bottom: 11px solid transparent;
}

.bookmark {
  z-index: 9999;
  color: #75449b;
  height: 30px;
  width: 24px;
  position: relative;
  border-radius: 0 0 1px 1px;
  border-top: solid 0px currentColor;
  border-left: solid 1px currentColor;
  border-right: solid 1px currentColor;
}

.bookmark:before {
  content: "";
  position: absolute;
  top: 21px;
  left: 3.5px;
  width: 16px;
  height: 16px;
  z-index: 0;
  border-top: solid 1px currentColor;
  border-left: solid 1px currentColor;
  transform: rotate(46deg);
  transition: transform 0.2s;
}

.bookmark:hover {
  top: 7px;
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform 250ms;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bookmark-filled:hover {
  top: 7px;
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform 250ms;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.ck.ck-editor {
  border: 1px solid #75459b;
  border-radius: 10px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: 0px 0px 10px 10px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 10px 10px 10px 10px;
}

.drawer-icon {
  height: 20.25px;
  width: 18px;
}

.action-btn {
  display: flex;
  justify-content: center;
  margin: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* background: transparent; */
  /* bottom: 0; */
  /* color: transparent; */
  /* cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto; */
}

.more-menu {
  display: flex;
  width: 100%;
  clear: both;
  background-color: transparent;
}

.dropdown .dropdown-menu {
  padding: 5px;
}

.dropdown-multi {
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 1px 1px 5px 1px #d9d1d1;
  padding: 4px;
  font-family: "Rubik";
}

.dropdown-multi .chip {
  background-color: #75459b;
  /* Set the desired background color for the chip */
  margin: 0px;
  color: white;
  /* Set the desired text color for the chip */
}

.search-datepicker {
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 1px 1px 5px 1px #d9d1d1;
  padding: 10px;
  background: #f6e8ff;
  font-family: "Rubik";
}

.field-icon {
  float: right;
  margin-left: -25px;
  right: 10px;
  margin-top: -29px;
  position: relative;
  z-index: 2;
  color: #75459b;
}

#table1_filter .form-control {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgICB2ZXJzaW9uPSIxLjEiICAgaWQ9InN2ZzQ0ODUiICAgdmlld0JveD0iMCAwIDIxLjk5OTk5OSAyMS45OTk5OTkiICAgaGVpZ2h0PSIyMiIgICB3aWR0aD0iMjIiPiAgPGRlZnMgICAgIGlkPSJkZWZzNDQ4NyIgLz4gIDxtZXRhZGF0YSAgICAgaWQ9Im1ldGFkYXRhNDQ5MCI+ICAgIDxyZGY6UkRGPiAgICAgIDxjYzpXb3JrICAgICAgICAgcmRmOmFib3V0PSIiPiAgICAgICAgPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+ICAgICAgICA8ZGM6dHlwZSAgICAgICAgICAgcmRmOnJlc291cmNlPSJodHRwOi8vcHVybC5vcmcvZGMvZGNtaXR5cGUvU3RpbGxJbWFnZSIgLz4gICAgICAgIDxkYzp0aXRsZT48L2RjOnRpdGxlPiAgICAgIDwvY2M6V29yaz4gICAgPC9yZGY6UkRGPiAgPC9tZXRhZGF0YT4gIDxnICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLC0xMDMwLjM2MjIpIiAgICAgaWQ9ImxheWVyMSI+ICAgIDxnICAgICAgIHN0eWxlPSJvcGFjaXR5OjAuNSIgICAgICAgaWQ9ImcxNyIgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjAuNCw4NjYuMjQxMzQpIj4gICAgICA8cGF0aCAgICAgICAgIGlkPSJwYXRoMTkiICAgICAgICAgZD0ibSAtNTAuNSwxNzkuMSBjIC0yLjcsMCAtNC45LC0yLjIgLTQuOSwtNC45IDAsLTIuNyAyLjIsLTQuOSA0LjksLTQuOSAyLjcsMCA0LjksMi4yIDQuOSw0LjkgMCwyLjcgLTIuMiw0LjkgLTQuOSw0LjkgeiBtIDAsLTguOCBjIC0yLjIsMCAtMy45LDEuNyAtMy45LDMuOSAwLDIuMiAxLjcsMy45IDMuOSwzLjkgMi4yLDAgMy45LC0xLjcgMy45LC0zLjkgMCwtMi4yIC0xLjcsLTMuOSAtMy45LC0zLjkgeiIgICAgICAgICBjbGFzcz0ic3Q0IiAvPiAgICAgIDxyZWN0ICAgICAgICAgaWQ9InJlY3QyMSIgICAgICAgICBoZWlnaHQ9IjUiICAgICAgICAgd2lkdGg9IjAuODk5OTk5OTgiICAgICAgICAgY2xhc3M9InN0NCIgICAgICAgICB0cmFuc2Zvcm09Im1hdHJpeCgwLjY5NjQsLTAuNzE3NiwwLjcxNzYsMC42OTY0LC0xNDIuMzkzOCwyMS41MDE1KSIgICAgICAgICB5PSIxNzYuNjAwMDEiICAgICAgICAgeD0iLTQ2LjIwMDAwMSIgLz4gICAgPC9nPiAgPC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-color: #fff;
  padding: 0 0 0 27px;
  background-position: 3px 4px !important;
}

.btn-facebook {
  background: #3b579d;
  color: #ffffff;
}

.btn-instagram {
  background: linear-gradient(45deg,
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d);
  color: #ffffff;
}

.btn-linkedin {
  background: #0a66c2;
  color: #ffffff;
}

.btn-whatsapp {
  background: #00cf4d;
  color: #ffffff;
}

.btn-twitter {
  background: #1da1f2;
  color: #ffffff;
}

.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}

.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

.btn-left {
  left: 0.4em;
}

.btn-right {
  right: 0.4em;
}

.btn-left,
.btn-right {
  position: absolute;
  top: 0.24em;
}

.dropbtn {
  background-color: #4caf50;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

.dotdropdown {
  position: relative;
  display: flex;
  right: 0.4em;
}

.dotdropdown-content {
  display: none;
  position: relative;
  margin-top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dotdropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dotdropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.content-box {
  position: relative;
}

#ribbon-container {
  position: absolute;
  top: -9px;
  right: -32px;
  overflow: visible;
  font-size: 16px;
  line-height: 0px;
}

/* PENDING */
#ribbon-container-pending {
  position: absolute;
  top: -9px;
  right: -32px;
  overflow: visible;
  font-size: 16px;
  line-height: 0px;
}

#ribbon-container-pending:before {
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  border-top: 20px solid rgba(0, 0, 0, 0.3);
  border-bottom: 20px solid rgba(0, 0, 0, 0.3);
  border-right: 20px solid rgba(0, 0, 0, 0.3);
  border-left: 29px solid transparent;
}

#ribbon-container-pending:after {
  content: "";
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: absolute;
  bottom: -2px;
  left: 48px;
  right: 3px;
}

#ribbon-container-pending a {
  padding-left: 10px !important;
  display: block;
  padding: 20px;
  position: relative;
  /* allows us to position our pseudo-elements properly */
  background: #ffc107;
  overflow: visible;
  height: 40px;
  margin-left: 29px;
  color: #fff;
  text-decoration: none;
}

#ribbon-container-pending a:after {
  /* this creates the "folded" part of our ribbon */

  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  bottom: -15px;
  right: 0;
  border-top: 15px solid #ffc107;
  border-right: 15px solid transparent;
}

#ribbon-container-pending a:before {
  /* this creates the "forked" part of our ribbon */
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: -29px;
  border-top: 20px solid #ffc107;
  border-bottom: 20px solid #ffc107;
  border-right: 20px solid transparent;
  border-left: 29px solid transparent;
}

/* REJECT */
#ribbon-container-reject {
  position: absolute;
  top: -9px;
  right: -32px;
  overflow: visible;
  font-size: 16px;
  line-height: 0px;
}

#ribbon-container-reject:before {
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  border-top: 20px solid rgba(0, 0, 0, 0.3);
  border-bottom: 20px solid rgba(0, 0, 0, 0.3);
  border-right: 20px solid rgba(0, 0, 0, 0.3);
  border-left: 29px solid transparent;
}

#ribbon-container-reject:after {
  content: "";
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: absolute;
  bottom: -2px;
  left: 48px;
  right: 3px;
}

#ribbon-container-reject a {
  padding-left: 10px !important;
  display: block;
  padding: 20px;
  position: relative;
  /* allows us to position our pseudo-elements properly */

  background: red;
  overflow: visible;
  height: 40px;
  margin-left: 29px;
  color: #fff;
  text-decoration: none;
}

#ribbon-container-reject a:after {
  /* this creates the "folded" part of our ribbon */

  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  bottom: -15px;
  right: 0;
  border-top: 15px solid red;
  border-right: 15px solid transparent;
}

#ribbon-container-reject a:before {
  /* this creates the "forked" part of our ribbon */
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: -29px;
  border-top: 20px solid red;
  border-bottom: 20px solid red;
  border-right: 20px solid transparent;
  border-left: 29px solid transparent;
}

/* ACCEPT */
#ribbon-container-accept {
  position: absolute;
  top: -9px;
  right: -32px;
  overflow: visible;
  font-size: 16px;
  line-height: 0px;
}

#ribbon-container-accept:before {
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  border-top: 20px solid rgba(0, 0, 0, 0.3);
  border-bottom: 20px solid rgba(0, 0, 0, 0.3);
  border-right: 20px solid rgba(0, 0, 0, 0.3);
  border-left: 29px solid transparent;
}

#ribbon-container-accept:after {
  content: "";
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: absolute;
  bottom: -2px;
  left: 48px;
  right: 3px;
}

#ribbon-container-accept a {
  padding-left: 10px !important;
  display: block;
  padding: 20px;
  position: relative;
  /* allows us to position our pseudo-elements properly */

  background: #28a745;
  overflow: visible;
  height: 40px;
  margin-left: 29px;
  color: #fff;
  text-decoration: none;
}

#ribbon-container-accept a:after {
  /* this creates the "folded" part of our ribbon */

  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  bottom: -15px;
  right: 0;
  border-top: 15px solid #28a745;
  border-right: 15px solid transparent;
}

#ribbon-container-accept a:before {
  /* this creates the "forked" part of our ribbon */
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: -29px;
  border-top: 20px solid #28a745;
  border-bottom: 20px solid #28a745;
  border-right: 20px solid transparent;
  border-left: 29px solid transparent;
}

.rmdp-input {
  width: 95% !important;
  height: 22px !important;
}

.react-timerange-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}

.rmdp-container {
  display: block !important;
}

.slot {
  border-width: 0.5px;
  width: 110px;
  border-right: 1px solid #dcdcdc;
  border-radius: 50px 0px 0px 50px;
  padding: 3px 0px 2px 12px;
}

.slot-right {
  border-width: 0.5px;
  width: 100px;
  border-right: 1px solid #dcdcdc;
  padding: 3px 0px 2px 12px;
}

.slot-to {
  border-width: 1px;
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  padding: 3px 5px 3px 5px;
}

.slot-sheet {
  width: 16% !important;
}

.slot-qty {
  border-width: 0.5px;
  width: 140px;
  margin-bottom: 10px;
  border-left: 1px solid #dcdcdc;
  border-radius: 0px 50px 50px 0px;
  padding: 3px 0px 2px 5px;
}

.slot-qty-filled {
  border-width: 0.5px;
  padding-left: 5px;
  width: 80px;
  padding-top: 2.5px;
  padding-bottom: 1.5px;
  margin-bottom: 10px;
  border-right: 2px solid #11d811;
  border-top: 2px solid #11d811;
  border-bottom: 2px solid #11d811;
  border-radius: 0px 50px 50px 0px;
}

.slot-qty-unfilled {
  border-width: 0.5px;
  padding-left: 5px;
  width: 80px;
  padding-top: 2.5px;
  padding-bottom: 1.5px;
  margin-bottom: 10px;
  border-right: 2px solid #11d811;
  border-top: 2px solid #11d811;
  border-bottom: 2px solid #11d811;
  border-radius: 0px 0px 0px 0px;
}

.slot-qty-sheet {
  border-width: 0.5px;
  padding-left: 5px;
  width: 80px;
  padding-top: 2.5px;
  padding-bottom: 1.5px;
  margin-bottom: 10px;
  border-right: 1px solid #000000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 0px 50px 50px 0px;
}

.unfilled-slot-qty-sheet {
  border-width: 0.5px;
  padding-left: 5px;
  width: 80px;
  padding-top: 2.5px;
  padding-bottom: 1.5px;
  margin-bottom: 10px;
  border-right: 1px solid #000000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
}

.label-slots {
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #11d811;
  border-radius: 50px;
}

.label-slots-text {
  border-right: 1px solid #11d811;
  padding-left: 10px;
  padding-right: 10px;
}

/* NEW SLOT DESIGN SINGLE BORDER */
/* DEFAULT SLOT VIEW */
.all-slot-border {
  border-radius: 20px;
  padding: 1px 15px 1px 15px;
  width: fit-content;
}

.start-date-width {
  width: 90px;
  padding-left: 5px;
}

.slot-quantity-width {
  width: 120px;
  border-width: 0px 0px 0px 1px;
}

.slot-time-to {
  padding: 0px 10px 0px 10px;
}

.react-timerange-picker__wrapper {
  border: transparent;
}

.react-timerange-picker--disabled {
  background-color: #ffffff;
  color: #000000;
}

.deleted {
  position: relative;
}

.deleted:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: rgb(255, 0, 0);
  transform: translateY(-50%);
}

.green-box {
  width: 17px;
  border: 2px solid #11d811;
  background: #11d811;
}

.green-border {
  border: 2px solid #11d811 !important;
  border-right-width: 0px !important;
  color: #11a011;
}

.green-border-tc-slot-left {
  border: 2px solid #11d811 !important;
  border-right-width: 0px !important;
  color: #11a011;
}

.green-border-tc-slot-right {
  border: 2px solid #11d811 !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  color: #11a011;
}

.green-border-tc-slot-to {
  border: 2px solid #11d811 !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  color: #11a011;
  padding: 3px 0px 3px 0px;
}

.red-border-tc-slot-left {
  border: 2px solid #d81717 !important;
  border-right-width: 0px !important;
  color: #d81717;
}

.red-border-tc-slot-right {
  border: 2px solid #d81717 !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  color: #d81717;
}

.red-border-tc-slot-to {
  border: 2px solid #d81717 !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  color: #d81717;
  padding: 3px 0px 3px 0px;
}

.red-border {
  /* border: 2px solid #d81717 !important; */
  border-right-width: 0px !important;
  color: #d81717;
}

.warning-border {
  border: 2px solid #f0ad4e !important;
  border-right-width: 0px !important;
  color: #000;
}

.timer-filled {
  border: 2px solid #11d811;
  border-radius: 0px 50px 50px 0px;
  padding: 2px;
}

.timer-filled-admin {
  border: 2px solid #11d811;
  border-radius: 0px 0px 0px 0px;
  padding: 2px;
}

.timer-partial {
  border: 2px solid #f0ad4e;
  border-radius: 0px 0px 0px 0px;
  padding: 1px;
}

.qty-partial {
  border-right: 2px solid #f0ad4e;
  border-top: 2px solid #f0ad4e;
  border-bottom: 2px solid #f0ad4e;
}

.timer {
  border: 1px solid #000;
  border-radius: 0px 50px 50px 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.timer-admin {
  border: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.timer-admin-end {
  border: 1px solid #000;
  border-radius: 0px 50px 50px 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.timer-border {
  border: 1px solid #000;
  border-radius: 0px 50px 50px 0px;
}

.timer-form {
  border: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.slight-padding {
  padding: 6px;
  padding-bottom: 7px;
}

.slot-staff {
  padding: 3px 0px 2px 12px;
  width: 92px;
  border-radius: 50px 0px 0px 50px;
}

#slot-type {
  height: 30px !important;
  background-color: snow;
}

.timer-staff-filled {
  border: 2px solid #11d811;
  border-radius: 0px 17px 17px 0px;
  padding: 2px;
}

.timer-staff-error {
  border: 2px solid #d81717;
  border-radius: 0px 17px 17px 0px;
  padding: 2px;
}

.timer-staff {
  border: 1px solid #000;
  border-radius: 0px 17px 17px 0px;
  padding: 2px;
}

.timer-left-client {
  border: 1px solid #000;
  padding: 3px 0px 2px 6px;
  border-width: 1px 0px 1px 1px;
}

.timer-right-client {
  border: 1px solid #000;
  padding: 3px 0px 2px 6px;
  border-width: 1px 1px 1px 0px;
}

.client-to {
  border: 1px solid #000;
  border-width: 1px 0px 1px 0px;
  padding: 7px 3px 7.2px 3px;
}

.custom-placeholder {
  color: gray;
  font-style: italic;
}

.float-btn {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 5;
}

.status-steps {
  font-size: 1rem;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

#progress-bar {
  /* margin-bottom: 30px; */
  overflow: hidden;
  color: lightgrey;
}

#progress-bar .active {
  color: #673ab7;
}

#progress-bar li {
  list-style-type: none;
  font-size: 15px;
  /* width: 15%; */
  text-align: center;
  position: relative;
  font-weight: 400;
}

.tab-step-icon {
  position: absolute;
  color: #fff;
  z-index: 2;
  line-height: 50px;
}

#progress-bar #account:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f023";
}

#progress-bar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  padding: 2px;
  z-index: 2;
  padding-left: 0rem !important;
}

#progress-bar li.active:before,
#progress-bar li.active:after {
  background: #673ab7;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #fed713;
}

.progress-complete {
  background-color: #28a745;
}

.fs-title {
  font-size: 1.2rem;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animateForm {
  visibility: visible;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

#select-disabled {
  opacity: 1 !important;
}

.wrapper {
  background: #f6f6f6;
  /* max-width: 360px; */
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.wrapper .content {
  /* padding: 30px; */
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wrapper .outer {
  height: 135px;
  width: 135px;
  overflow: hidden;
}

.outer .emojis {
  height: 500%;
  display: flex;
  flex-direction: column;
}

.outer .emojis li {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  transition: all 0.3s ease;
}

.outer li img {
  height: 70%;
  width: 70%;
}

#star-1:checked~.content .emojis .slideImg {
  margin-top: -135px;
}

#star-2:checked~.content .emojis .slideImg {
  margin-top: -270px;
}

#star-3:checked~.content .emojis .slideImg {
  margin-top: -405px;
}

#star-4:checked~.content .emojis .slideImg {
  margin-top: -540px;
}

#star-5:checked~.content .emojis .slideImg {
  margin-top: -675px;
}

.wrapper .stars {
  margin-top: 20px;
}

.stars label {
  font-size: 30px;
  margin: 0 3px;
  color: #ccc;
}

#star-1:hover~.content .stars .star-1,
#star-1:checked~.content .stars .star-1,
#star-2:hover~.content .stars .star-1,
#star-2:hover~.content .stars .star-2,
#star-2:checked~.content .stars .star-1,
#star-2:checked~.content .stars .star-2,
#star-3:hover~.content .stars .star-1,
#star-3:hover~.content .stars .star-2,
#star-3:hover~.content .stars .star-3,
#star-3:checked~.content .stars .star-1,
#star-3:checked~.content .stars .star-2,
#star-3:checked~.content .stars .star-3,
#star-4:hover~.content .stars .star-1,
#star-4:hover~.content .stars .star-2,
#star-4:hover~.content .stars .star-3,
#star-4:hover~.content .stars .star-4,
#star-4:checked~.content .stars .star-1,
#star-4:checked~.content .stars .star-2,
#star-4:checked~.content .stars .star-3,
#star-4:checked~.content .stars .star-4,
#star-5:hover~.content .stars .star-1,
#star-5:hover~.content .stars .star-2,
#star-5:hover~.content .stars .star-3,
#star-5:hover~.content .stars .star-4,
#star-5:hover~.content .stars .star-5,
#star-5:checked~.content .stars .star-1,
#star-5:checked~.content .stars .star-2,
#star-5:checked~.content .stars .star-3,
#star-5:checked~.content .stars .star-4,
#star-5:checked~.content .stars .star-5 {
  color: #fd4;
}

.wrapper .footer {
  border-top: 1px solid #ccc;
  background: #f2f2f2;
  width: 100%;
  height: 55px;
  padding: 0 20px;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer span {
  font-size: 17px;
  font-weight: 400;
}

.footer .text::before {
  content: "How was your experience?";
  font-size: 0.9em;
}

.footer .numb::before {
  content: "0 out of 5";
}

#star-1:checked~.footer .text::before {
  content: "Needs improvement";
  font-size: 0.9em;
}

#star-1:checked~.footer .numb::before {
  content: "1 out of 5";
}

#star-2:checked~.footer .text::before {
  content: "Thank you for your feedback. We'll take it into consideration as we work to improve our services.";
  font-size: 0.9em;
}

#star-2:checked~.footer .numb::before {
  content: "2 out of 5";
}

#star-3:checked~.footer .text::before {
  content: "We appreciate your feedback. Is there anything we can do to make your experience even better?";
  font-size: 0.9em;
}

#star-3:checked~.footer .numb::before {
  content: "3 out of 5";
}

#star-4:checked~.footer .text::before {
  content: "Thank you for your positive feedback! We're glad you enjoyed your experience";
  font-size: 0.9em;
}

#star-4:checked~.footer .numb::before {
  content: "4 out of 5";
}

#star-5:checked~.footer .text::before {
  content: "Wow, thank you for your glowing review! We're thrilled to hear that you had such a great experience with us.";
  font-size: 0.9em;
}

#star-5:checked~.footer .numb::before {
  content: "5 out of 5";
}

#rating-input {
  display: none;
}

.animate-btn {
  transition: all 0.1s;
}

.animate-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.animate-btn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.animate-btn:hover span {
  padding-right: 25px;
}

.animate-btn:hover span:after {
  opacity: 1;
  right: 0;
}

.tooltip-inner {
  min-width: 800px;
}

.accordion-section {
  display: flex;
}

.accordion-header-right {
  flex-grow: 1;
  text-align: right;
}

.accordion-body-left {
  flex-grow: 1;
}

.signature-canvas {
  border: 1px solid #8650b2;
  border-radius: 8px;
}

.border-check {
  border: 2px solid #a764db;
  /* animation: fillBorder 1s ease-in forwards; */
  border-radius: 18px;
}

.rating-count {
  font-size: 15px;
}

.btn--glow {
  position: relative;
  overflow: hidden;
}

.btn--glow:before {
  content: "";
  position: absolute;
  left: -20%;
  top: -10%;
  width: 0;
  height: 100%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 5s ease-in-out infinite;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }

  50% {
    left: 120%;
  }

  100% {
    left: 120%;
  }
}

@keyframes fillBorder {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 100%;
    height: 0;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .float-search {
    position: relative;
  }

  .float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .home-float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .top-padding {
    top: 0px !important;
  }

  .profile-img {
    position: absolute;
    width: 100px;
    border-radius: 50%;
    margin-left: 20px;
  }

  .img-res {
    max-width: 100%;
  }

  .tab-bar-name {
    font-size: 12px;
  }

  .progress-width {
    width: 100% !important;
  }

  .progress-bar-mobile {
    display: flex;
  }

  .date-picker-mobile {
    width: 100px;
  }

  .time-picker-mobile {
    width: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .float-search {
    position: relative;
  }

  .float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .home-float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .top-padding {
    top: 0px !important;
  }

  .tab-bar-name {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .float-search {
    position: relative;
  }

  .float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .home-float-search-staffs {
    position: relative;
    width: auto;
    text-align: center;
  }

  .top-padding {
    top: 0px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .float-search {
    position: relative;
  }

  .float-search-staffs {
    position: relative;
    width: auto;
  }

  .home-float-search-staffs {
    position: relative;
    width: auto;
    top: 75%;
  }

  .top-padding {
    top: 0px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .float-search {
    position: relative;
  }

  .float-search-staffs {
    position: relative;
    width: auto;
  }

  .home-float-search-staffs {
    position: relative;
    width: auto;
    top: 75%;
  }

  .top-padding {
    top: 0px !important;
  }
}

@media only screen and (max-width: 2000px) and (min-width: 1900px) {
  .home-float-search-staffs {
    top: 70%;
  }

  .float-search {
    top: 70% !important;
  }

  .border-check {
    border: 2px solid #a764db;
    animation: fillBorder 1s ease-in forwards;
    border-radius: 18px;
  }
}

@media only screen and (min-height: 800px) and (max-height: 1150px) {
  .float-search {
    top: 70%;
  }

  .float-search-shift {
    top: 18% !important;
  }

  .home-float-search-staffs {
    top: 70%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1900px) {
  .border-check {
    animation: fillBorder 1s ease-in forwards;
  }
}

.label-login-tab-small {
  transition: font-size 0.3s ease;
}

/* Apply the animation to an element */
.label-login-tab-big {
  font-size: 18px !important;
  transition: font-size 0.3s ease;
}

.login-switch {
  z-index: -1;
  position: absolute;
  opacity: 0;
}

.login-switch-label {
  position: relative;
  display: flex;
  align-items: center;
  grid-template-columns: 0px 100px;
  grid-gap: 10px;
  white-space: nowrap;
  color: gray;
  height: 62px;
  padding: 0 20px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: transparent;
  transition: all 300ms ease-in;
}

.login-switch:checked~label:after {
  will-change: opacity, box-shadow;
  -webkit-animation: sparkles 700ms ease-in-out;
  animation: sparkles 700ms ease-in-out;
}

.login-switch:checked~label>span {
  will-change: transform;
  /* border: 0;
    background-image: linear-gradient(to top right, #8282C8, #75459B); */
  -webkit-animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
  animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

.login-switch:checked~label>span:after {
  content: "\2713";
  font-size: 20px;
  font-weight: bolder;
  position: relative;
  top: -3px;
  left: 3px;
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: #fff; */
}

.login-switch-label:hover {
  border-color: #75459b;
}

.login-switch-label-selected {
  background-color: rgba(97, 154, 234, 0.16);
  border-radius: 6px;
  border: 2px solid #75459b;
}

.login-switch-label:after {
  position: absolute;
  left: 29px;
  border-radius: 50%;
  content: "";
}

.login-switch-label:before {
  margin: -2rem;
  border: solid 2rem #545461;
  width: 4rem;
  height: 4rem;
  transform: scale(0);
}

.login-switch-label:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
  box-shadow: 0.32476rem -2.6875rem 0 -0.1875rem #ff8080,
    -0.32476rem -2.3125rem 0 -0.1875rem #ffed80,
    2.30366rem -1.42172rem 0 -0.1875rem #ffed80,
    1.6055rem -1.69573rem 0 -0.1875rem #a4ff80,
    2.54785rem 0.91464rem 0 -0.1875rem #a4ff80,
    2.32679rem 0.19796rem 0 -0.1875rem #80ffc8,
    0.87346rem 2.56226rem 0 -0.1875rem #80ffc8,
    1.29595rem 1.94258rem 0 -0.1875rem #80c8ff,
    -1.45866rem 2.28045rem 0 -0.1875rem #80c8ff,
    -0.71076rem 2.2244rem 0 -0.1875rem #a480ff,
    -2.69238rem 0.28141rem 0 -0.1875rem #a480ff,
    -2.18226rem 0.8312rem 0 -0.1875rem #ff80ed,
    -1.89869rem -1.92954rem 0 -0.1875rem #ff80ed,
    -2.01047rem -1.18791rem 0 -0.1875rem #ff8080;
}

.login-switch-label>span {
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #8282c8;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
}

.dt-buttons {
  float: right !important;
  margin-right: 10px;
}

.dt-buttons>.buttons-print,
.dt-buttons>.buttons-copy,
.dt-buttons>.buttons-csv,
.dt-buttons>.buttons-pdf,
.dt-buttons>.buttons-excel {
  background-color: #8282c8;
  color: white;
  /* Green background */
  border: none;
  /* White text color */
  padding: 5px 10px;
  margin-top: 5px;
  /* Add some padding */
  text-align: center;
  /* Center the text */
  text-decoration: none;
  /* Remove underline */
  display: inline-block;
  /* Display as inline block */
  font-size: 14px;
  /* Set font size */
  cursor: pointer;
  /* Add cursor on hover */
  border-radius: 4px;
  /* Add border radius */
}

.dt-buttons>.buttons-print:hover,
.dt-buttons>.buttons-copy:hover,
.dt-buttons>.buttons-csv:hover,
.dt-buttons>.buttons-pdf:hover,
.dt-buttons>.buttons-excel:hover {
  color: #fff;
  background-color: #75459b !important;
}

.dataTables_length {
  padding-left: 5px !important;
}

/* this is for filter */
.datepicker table td.day {
  padding: 5px;
}

.datepicker .clear {
  background-color: #8282c8;
  color: #fff;
  border-color: #8282c8;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.datepicker .clear:hover {
  background-color: #75459b;
  border-color: #75459b;
  color: #fff;
}

.rmdp-day.rmdp-selected .highlight-red {
  background-color: #ea0034 !important;
}

.alias {
  cursor: alias;
}

.help {
  cursor: help;
}

.no-drop {
  cursor: no-drop;
}

/* NEW MODULE */
.custom-modal-size .modal-dialog {
  max-width: 650px;
  /* Adjust the width to your desired size */
}

.suffix-container {
  background-color: #75459b;
  height: 15px;
  width: auto;
  border-radius: 5px;
  position: absolute;
  top: -15px;
  line-height: 15.5px;
  left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.suffix-container-cancelled {
  background-color: #75459b;
  height: 15px;
  width: auto;
  border-radius: 5px;
  position: absolute;
  top: -15px;
  line-height: 15.5px;
  right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.suffix-lable {
  font-size: 11px;
  font-weight: initial;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
}

.tab-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.tab-buttons {
  display: flex;
}

.tab-button {
  flex: 1;
  padding: 10px;
  background-color: #f1f1f1;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #75459b;
  color: white;
}

.tab-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.loading-dots {
  text-align: center;
}

.loading-dots span {
  display: inline-block;
  width: 3px;
  height: 3px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #000;
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.tab-list-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.tab-list-content.active {
  max-height: fit-content;
  /* Adjust this value according to your content's height */
  opacity: 1;
}



.extention-floating-button {
  position: fixed;
  top: 150px;
  color: #ffffff;
  font-size: 24px;
  border: none;
  cursor: pointer;
  right: -141px;
  transition: 0.3s;
  padding: 15px;
  border-radius: 0 5px 5px 0;
}

.extention-floating-button:hover {
  right: 0;
  /* On mouse-over, make the elements appear as they should */
}
.pending-floating-button {
  position: fixed;
  top: 150px;
  color: #ffffff;
  font-size: 24px;
  border: none;
  cursor: pointer;
  right: -219px;
  transition: 0.3s;
  padding: 15px;
  border-radius: 0 5px 5px 0;
}

.pending-floating-button:hover {
  right: 0;
  /* On mouse-over, make the elements appear as they should */
}

.custom-radio:checked {
  border-color: green;
  /* Change the border color when checked */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
    /* Half rotation */
  }

  100% {
    transform: rotate(360deg);
    /* Full rotation */
  }
}

.rotate-clock {
  font-size: 20px;
  animation: rotate 2s ease-in-out 1s infinite;
  /* 2s for one full rotation + 1s pause */
}

.back-home-btn {
  padding: 15px 15px;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background-color: rgba(240, 85, 85, 0.9);
  box-shadow: -7px 7px 0 rgba(72, 104, 244, 0.7),
    -12px 12px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
}

.back-home-btn:hover {
  box-shadow: -3px 3px 0 rgba(72, 104, 244, 0.7),
    -6px 6px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
  color: #75459b;
}

.pdf-page-break {
  page-break-before: always;
  /* Insert page break before this element */
}

/*progressbar*/
#progressbar {
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: #8282c8;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  line-height: 38px;
  display: block;
  font-size: 25px;
  color: #fff;
  background: #afafee;
  border-radius: 3px;
  margin: 0 auto 5px auto;
  position: relative;
  z-index: 2;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: rgb(190, 190, 190);
  position: absolute;
  left: -50%;
  top: 17px;
  z-index: 1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #75459b;
  color: white;
}

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(5px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes radio {

  0%,
  17.5% {
    transform: scale(0);
  }
}

@keyframes radio {

  0%,
  17.5% {
    transform: scale(0);
  }
}

@-webkit-keyframes shine {
  from {
    transform: translateX(-70px) rotate(10deg);
  }

  to {
    transform: translateX(300px) rotate(10deg);
  }
}

@keyframes shine {
  from {
    transform: translateX(-70px) rotate(10deg);
  }

  to {
    transform: translateX(300px) rotate(10deg);
  }
}

@-webkit-keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #545461;
    border-width: 2rem;
  }

  30%,
  100% {
    transform: scale(1);
    border-color: #545461;
    border-width: 0;
  }
}

@keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #545461;
    border-width: 2rem;
  }

  30%,
  100% {
    transform: scale(1);
    border-color: #545461;
    border-width: 0;
  }
}

@-webkit-keyframes sparkles {

  0%,
  10% {
    opacity: 0;
    transform: scale(0);
  }

  15% {
    opacity: 1;
    transform: scale(1.2) rotate(-20deg);
    box-shadow: 0.32476rem -2.1875rem 0 0rem #ff8080,
      -0.32476rem -1.8125rem 0 0rem #ffed80,
      1.91274rem -1.10998rem 0 0rem #ffed80,
      1.21459rem -1.38398rem 0 0rem #a4ff80,
      2.06039rem 0.80338rem 0 0rem #a4ff80, 1.83932rem 0.0867rem 0 0rem #80ffc8,
      0.65652rem 2.11178rem 0 0rem #80ffc8, 1.07901rem 1.4921rem 0 0rem #80c8ff,
      -1.24172rem 1.82996rem 0 0rem #80c8ff,
      -0.49382rem 1.77391rem 0 0rem #a480ff,
      -2.20492rem 0.17015rem 0 0rem #a480ff,
      -1.69479rem 0.71994rem 0 0rem #ff80ed,
      -1.50777rem -1.61779rem 0 0rem #ff80ed,
      -1.61955rem -0.87617rem 0 0rem #ff8080;
  }
}

@keyframes sparkles {

  0%,
  10% {
    opacity: 0;
    transform: scale(0);
  }

  15% {
    opacity: 1;
    transform: scale(1.2) rotate(-20deg);
    box-shadow: 0.32476rem -2.1875rem 0 0rem #ff8080,
      -0.32476rem -1.8125rem 0 0rem #ffed80,
      1.91274rem -1.10998rem 0 0rem #ffed80,
      1.21459rem -1.38398rem 0 0rem #a4ff80,
      2.06039rem 0.80338rem 0 0rem #a4ff80, 1.83932rem 0.0867rem 0 0rem #80ffc8,
      0.65652rem 2.11178rem 0 0rem #80ffc8, 1.07901rem 1.4921rem 0 0rem #80c8ff,
      -1.24172rem 1.82996rem 0 0rem #80c8ff,
      -0.49382rem 1.77391rem 0 0rem #a480ff,
      -2.20492rem 0.17015rem 0 0rem #a480ff,
      -1.69479rem 0.71994rem 0 0rem #ff80ed,
      -1.50777rem -1.61779rem 0 0rem #ff80ed,
      -1.61955rem -0.87617rem 0 0rem #ff8080;
  }
}

/* REVIEW RESPONSE */

.rating-employee {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 4px;
  padding: 10px 20px;
  /* border: 3px solid #111;
    border-radius: 30px;
    background-color: #353535;
    box-shadow: 30px 30px 30px -10px rgba(0, 0, 0, 0.5),
        inset 15px 15px 3px rgba(255, 255, 255, 0.1),
        -4px -4px 15px rgba(255, 255, 255, 0.1),
        inset -2px -2px 12px rgba(0, 0, 0, 0.75); */
}

.rating-employee input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

.rating-employee input::before {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-size: 34px;
  color: #111;
  transition: 0.5s;
}

.rating-employee input:hover~input::before,
.rating-employee input:hover::before,
.rating-employee input:checked~input::before,
.rating-employee input:checked::before {
  color: var(--c);
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
  border-top: solid 1px #75449b;
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.nav-link,
.bottom-nav-link {
  color: #55575b;
}

.bottom-nav-link.active {
  color: #922c88;
}

.bottom-tab-label {
  font-size: 12px;
}

.navbar-bottom {
  background-color: #fff;
  bottom: 0;
}

@keyframes blinker {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

.blink {
  animation-name: blinker;
  animation-duration: 1.4s;
  /* Adjust the duration as needed */
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* Calendar View CSS */
.rbc-day-bg {
  /* background-color: #CFE3D0;
  border-top: 1px solid rgb(137, 137, 137);
  border-bottom: 0px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black; */
}

.rbc-off-range-bg {
  /* background-color: #ffffff;
  border-color: #000; */
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: end;
  font-weight: bolder;
  color: #75449b;
}

.rbc-toolbar {
  justify-content: flex-start !important;

}

.rbc-header {
  background: #75449b;
  color: white !important;
  font-size: 17px !important;
  text-align: center;
  font-weight: 300 !important;
}


@media screen and (max-width: 600px) {
  .rbc-toolbar {
    flex-direction: column;
    /* Stack items vertically on small screens */
    text-align: center;
    /* Center-align text on small screens */
  }

  .slot-mobile-size {
    font-size: 10px;
  }
}


/* styles.css */



.shiftCard {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.shiftCard:hover {
  transform: translateY(-5px);
}

.rbc-row-content .rbc-row:not(:first-child):not(:nth-child(2)):not(:last-child) {
  display: none;
  /* Hide all elements except the first, second, and last */
}

.rbc-overlay {
  max-height: 200px;
  /* Set the maximum height of the overlay */
  overflow-y: auto;
  z-index: 100 !important;
  /* Enable vertical scrolling */
}

.rbc-show-more {
  position: absolute;
  top: 0;
  margin-left: 20px !important;
}

#selectAllCheckbox {
  /* Add your alignment styles here */
  /* For example, to center the checkbox horizontally: */
  display: block;
  margin: 0 auto;
  /* Centers the checkbox horizontally */
}

.equal-height-cards {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-cards .card {
  flex: 1;
}

.document-table-td {
  white-space: normal !important;
}

@keyframes eventRotateBorder {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the border */
.event-rotating-border {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
}

.event-rotating-border::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  border-radius: 10px;
  z-index: -1;
  transition: border-color 0.3s ease;
  /* Smooth transition effect */
  animation: eventRunBorder 3s linear infinite;
}

@keyframes eventRunBorder {
  0% {
    border-top-color: #3b99fc;
    border-right-color: #3b99fc;
  }

  25% {
    border-top-color: transparent;
    border-right-color: #3b99fc;
  }

  50% {
    border-right-color: transparent;
    border-bottom-color: #3b99fc;
  }

  75% {
    border-bottom-color: transparent;
    border-left-color: #3b99fc;
  }

  100% {
    border-left-color: transparent;
    border-top-color: #3b99fc;
  }
}

.date-picker-height {
  height: 40px !important;
}
