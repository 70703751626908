::selection {
  background: rgba(187, 134, 252, 0.5);
  color: #fff;
}

.container-tab {
  padding: 0px 5px;
  margin: 0px 5px;

}

.container-tab .topic {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.content-input {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.content .list {
  white-space: break-spaces;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 20%;
  height: 550px;
  overflow-y: scroll;
  /* Handle text overflow by hiding it */
  /* Prevent text from wrapping */
  text-overflow: ellipsis;
}

.content .list label {
  cursor: pointer;
  height: 60px;
  line-height: 39px;
  font-size: 22px;
  font-weight: 500;
  padding: 10px;
  transition: all 0.5s ease;
  z-index: 10;
}

#page:checked~.list label.page {
  color: #22272c;
}

.content .list label:hover {
  color: #22272c;
  /* Hover text color */
}

.slider {
  left: 0;
  top: 0;
  height: auto;
  width: 100%;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #bb86fc;
}


.content .text-content {
  width: 80%;
  color: rgba(0, 0, 0, 0.7);
}

.content .text {
  display: none;
}

.content-heading {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bolder;
  text-align: center;
}

.container-tab .text p {
  text-align: justify;
}

.content .text-content .page {
  display: block;
}

#page:checked~.text-content .page {
  display: block;
}


.upload-icon {
  width: "100%";
}

.upload-border {
  border: 1px solid #75459b;
  border-radius: 10px;
}
.input-title{
  text-align: left;
  font-weight: bold;
}